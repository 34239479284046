<template>
  <div class="comments-home">
    <h1>UnYoutube</h1>
    <input type="text" v-model="videoUrl" placeholder="Enter YouTube Video URL" @keyup.enter="loadVideo"/>
    <button @click="loadVideo">Load Video</button>
    <div v-if="localVideoId">
      <iframe width="560" height="315" :src="videoSrc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <comment-grid
              :key="nodeName"
              :video-id="localVideoId"
              :baseURL="baseURL"
              :apiKey="apiKey"
              :nodeName="nodeName">
    </comment-grid>
  </div>
  </div>
</template>

<script>
export default {
name: 'CommentsHome',
props: ['videoId'],
data() {
  return {
    videoUrl: '',
    localVideoId: this.videoId,
    baseURL: 'https://comments-section-85458-default-rtdb.firebaseio.com/',
    apiKey: 'AIzaSyBoCSUviQpJpYeg41U8aMj6VtK9j8t5JH8',
    //nodeName: ''
  };
},
computed: {
  videoSrc() {
    return this.localVideoId ? `https://www.youtube.com/embed/${this.videoId}` : '';
  },

  nodeName() {
    return `video${this.localVideoId}`;
  }
},
methods: {
  loadVideo() {
    const match = this.videoUrl.match(/(?:https?:\/\/)?(?:www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/);
    if (match) {
      const newVideoId = match[2];
      if (this.localVideoId !== newVideoId) {
        console.log("Loading new video:", newVideoId);
        this.localVideoId = newVideoId;
        //this.nodeName = `video${newVideoId}`;
        this.$router.push({ path: '/watch', query: { v: newVideoId } }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      }
    } else {
      //alert('Please enter a valid YouTube URL.');
    }
  }
},
watch: {
  // videoId(newVal) {
  //   this.localVideoId = newVal;
  //   //this.nodeName = `video${newVal}`;
  // },



  videoId: {
    immediate: true,
    handler(newVal) {
      if (newVal !== this.localVideoId) {
        this.localVideoId = newVal;  // Ensure updates are reactive
      }
    }

  //new
//   localVideoId(newVal, oldVal) {
//   console.log(`Video ID changed from ${oldVal} to ${newVal}`);
//   this.nodeName = `video${newVal}`;  // Ensure this updates
// }
  }
},
mounted() {
  // if (this.videoId) {
  //   this.loadVideo();
  // }
  if (this.videoId) {
    this.loadVideo();  // Ensure initial load if videoId is present
  }
}
};
</script>

<style scoped>
.comments-home {
  max-width: 800px;
  margin: auto;
  text-align: center;
}
</style>