import Vue from 'vue'
import App from './App.vue'
import CommentGrid from 'vue-comment-grid'
import router from './router'
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from './firebase'

//new
initializeApp(firebaseConfig)

Vue.use(CommentGrid)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')