import Vue from 'vue';
import Router from 'vue-router';
import CommentsHome from '@/views/Home.vue'; 

Vue.use(Router);

// Setting up the router with the history mode and appropriate route definitions
export default new Router({
    mode: 'history',  // Enables cleaner URLs without hash (#) symbols
    routes: [
      {
        path: '/',
        redirect: '/watch'  // Matches paths like /watch?v=videoId
        
      },
      
      {
        path:'/watch',
        name: 'CommentsHome',
        component: CommentsHome,
        // This function maps the query parameter v directly to the videoId prop of the CommentsHome component
        props: (route) => ({ videoId: route.query.v })  // Directly pass videoId as a prop from the query parameter
      }
    ]
});
