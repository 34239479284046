import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBoCSUviQpJpYeg41U8aMj6VtK9j8t5JH8",
    authDomain: "comments-section-85458.firebaseapp.com",
    databaseURL: "https://comments-section-85458-default-rtdb.firebaseio.com",
    projectId: "comments-section-85458",
    storageBucket: "comments-section-85458.appspot.com",
    messagingSenderId: "306922083423",
    appId: "1:306922083423:web:1750edbd331b02fcd337ea",
    measurementId: "G-XDTL4PQ2D9"
  };

  const app = initializeApp(firebaseConfig);
  const db = getDatabase(app);
  const auth = getAuth(app);
  
  export { db, auth, firebaseConfig};